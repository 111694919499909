import { RouteConfig } from 'vue-router';
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const router = async (routes: RouteConfig[]) => {
  const { default: Vue } = await import(/* webpackChunkName: "vue" */ 'vue');

  const { default: VueRouter } = await import(/* webpackChunkName: "vue-router" */ 'vue-router');
  Vue.use(VueRouter);

  return new VueRouter({
    routes
  });
};

export default router;
