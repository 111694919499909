import {
  AdminApiClient,
  ArtifactsApiClient,
  ClientAuthorisationApiClient,
  EntryPointArgsApiClient,
  FeatureTogglesApiClient,
  InputDataDefinitionsApiClient,
  OrganisationsApiClient,
  TemplatesApiClient,
  TrendsApiClient
} from '@/repositories';

export interface IContainer {
  adminApiClient: AdminApiClient;
  artifactsApiClient: ArtifactsApiClient;
  clientAuthorisationApiClient: ClientAuthorisationApiClient;
  entryPointArgsApiClient: EntryPointArgsApiClient;
  featureTogglesApiClient: FeatureTogglesApiClient;
  inputDataDefinitionsApiClient: InputDataDefinitionsApiClient;
  organisationsApiClient: OrganisationsApiClient;
  templatesApiClient: TemplatesApiClient;
  trendsApiClient: TrendsApiClient;
}

export default class Container implements IContainer {
  get adminApiClient(): AdminApiClient {
    return new AdminApiClient();
  }
  get artifactsApiClient(): ArtifactsApiClient {
    return new ArtifactsApiClient();
  }
  get clientAuthorisationApiClient(): ClientAuthorisationApiClient {
    return new ClientAuthorisationApiClient();
  }
  get entryPointArgsApiClient(): EntryPointArgsApiClient {
    return new EntryPointArgsApiClient();
  }
  get featureTogglesApiClient(): FeatureTogglesApiClient {
    return new FeatureTogglesApiClient();
  }
  get inputDataDefinitionsApiClient(): InputDataDefinitionsApiClient {
    return new InputDataDefinitionsApiClient();
  }
  get organisationsApiClient(): OrganisationsApiClient {
    return new OrganisationsApiClient();
  }
  get templatesApiClient(): TemplatesApiClient {
    return new TemplatesApiClient();
  }
  get trendsApiClient(): TrendsApiClient {
    return new TrendsApiClient();
  }

  static get featureTogglesApiClient(): FeatureTogglesApiClient {
    return new FeatureTogglesApiClient();
  }
}

export { Container };
