import { ThemeOptions } from 'vuetify/types/services/theme';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const vuetify = async (theme: ThemeOptions = { dark: false }) => {
  const { default: Vue } = await import(/* webpackChunkName: "vue" */ 'vue');
  const { default: Vuetify } = await import(/* webpackChunkName: "vuetify" */ 'vuetify/lib/framework');

  Vue.use(Vuetify);

  return new Vuetify({
    theme
  });
};

export default vuetify;
