import { StoreOptions, Store, ModuleTree } from 'vuex';
import Container from '@/container';
import { IRootState } from '../stores/rootState';

const store = async (stores: ModuleTree<IRootState>): Promise<Store<IRootState>> => {
  const { default: Vue } = await import(/* webpackChunkName: "vue" */ 'vue');

  const { default: Vuex } = await import(/* webpackChunkName: "vuex" */ 'vuex');
  Vue.use(Vuex);

  return new Vuex.Store({
    state: { container: new Container() },
    modules: stores,
    strict: true
  } as StoreOptions<IRootState>);
};

export default store;
